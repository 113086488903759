import React from 'react';
import BangerCard from '../components/BangerCard';
import Container from '../components/Container';
import logo1 from '../images/logo1.png';
import logo2 from '../images/logo2.png';
import logo3 from '../images/logo3.png';

const BANGERS = [
  {
    img: logo1,
  },
  {
    img: logo2,
  },
  {
    img: logo3,
  },
];

export default function BoysPage() {
  return (
    <Container>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 pt-8 pb-16">
        {BANGERS.map((banger, index) => <BangerCard key={index} img={banger.img} />)}
      </div>
    </Container>
  );
};
